import React, { useEffect, useRef } from "react";

const addScript = (src) =>
  new Promise((resolve) => {
    const script = [...document.head.children].find(
      (child) => child.getAttribute("src") === src
    );

    if (script) {
      if (script.getAttribute("data-loaded")) resolve();
      else script.addEventListener("load", resolve);

      return;
    }

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("src", src);
    scriptEl.addEventListener("load", () => {
      scriptEl.setAttribute("data-loaded", true);
      resolve();
    });
    document.head.appendChild(scriptEl);
  });

const states = {
  // "-1": "unstarted",
  0: "ended",
  1: "playing",
  2: "paused",
  // 3: "buffering",
  // 5: "video cued",
};

const YoutubePlayer = ({ view, value, model }) => {
  const valRef = useRef();
  valRef.current = value;
  const { updateInterval } = model;

  useEffect(() => {
    let updateTimer = false;
    const onStateChange = () => {
      const playerState = states[player.getPlayerState()];

      if (playerState === "playing" && valRef.current.playState === "paused")
        player.pauseVideo();

      if (playerState === "playing") {
        updateTimer = setInterval(updatePos, 200);
      } else if (updateTimer) {
        clearInterval(updateTimer);
        updateTimer = false;
        updatePos();
      }
    };

    let dbPos = view.value?.pos || 0;
    const updatePos = () => {
      const pos = player.getCurrentTime();
      if (Math.abs(dbPos - pos) > 2) view.setValue({ path: "pos", value: pos });

      if (parseInt(player.getDuration(), 10) - 2 < pos)
        view.setValue({ path: "playState", value: "ended" });
    };
    let mounted = true;

    const onChange = ({ id, value }) => {
      if (id === "pos") {
        dbPos = value.pos;

        if (Math.abs(player.getCurrentTime() - value.pos) > 0.5)
          player.seekTo(value.pos, true);
      }

      if (value.playState === states[player.getPlayerState()]) return;

      if (value.playState === "playing") player.playVideo();
      else {
        player.pauseVideo();
        if (Math.abs(player.getCurrentTime() - value.pos) > 0.5) return;

        setTimeout(
          () =>
            mounted &&
            view.setValue({ path: "pos", value: player.getCurrentTime() }),
          100
        );
      }
    };
    view.on("change", onChange);

    let player = false;

    const loadPlayer = () => {
      if (typeof window.YT !== "undefined" && window.YT.Player)
        player = new window.YT.Player("youtubeplayer", {
          height: "2160",
          width: "4096",
          modestbranding: true,
          videoId: valRef.current._id,
          cc_load_policy: 0,
          events: {
            onReady: () => {
              player.seekTo(valRef.current.pos, true);
              view.on("change", onChange);

              if (!valRef.current.duration)
                view.setValue({
                  path: "duration",
                  value: player.getDuration(),
                });
            },
            onStateChange,
          },
        });
      else setTimeout(() => mounted && loadPlayer(), 200);
    };
    addScript("https://www.youtube.com/iframe_api").then(loadPlayer);

    return () => {
      mounted = false;
      view.off("change", onChange);
    };
  }, [updateInterval, view]);

  return (
    <div>
      <div
        style={{ position: "absolute", width: "100%", height: "100%" }}
        id="youtubeplayer"
      />
    </div>
  );
};

export default YoutubePlayer;
