import React, { useEffect, useRef, useState } from "react";

const MediaYtsyncPlayer = ({ value, view, model }) => {
  const { id, ext } = value || {};
  const { controls, updateInterval, className, preload } = model;
  const [cached, setCached] = useState();
  const src = `/video/${id}.${ext}`;

  const ref = useRef();

  const valRef = useRef();
  valRef.current = value;

  useEffect(() => {
    if (!ref.current) return;
    const video = ref.current;

    const isPlaying = () =>
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2;

    const ended = () => {
      view.setValue({ path: "pos", value: 0 });
      view.setValue({ path: "playState", value: "ended" });
    };
    video.addEventListener("ended", ended);

    const error = (e) => {
      console.log("error", e.target.src);
    };
    video.addEventListener("error", error);

    let dbPos = view.value?.pos || 0;
    video.currentTime = dbPos;

    if (view.value.playState === "playing")
      setTimeout(() => {
        video.play();
        video.currentTime = dbPos;
      }, 300);

    const onChange = ({ id, value }) => {
      if (id === "pos") {
        dbPos = value.pos;

        if (Math.abs(video.currentTime - value.pos) > 0.5)
          video.currentTime = value.pos;
      }

      if (id === "playState") {
        console.log(value.playState);

        if (value.playState === "playing") video.play();
        else {
          video.pause();
          setTimeout(
            () => view.setValue({ path: "pos", value: video.currentTime }),
            0
          );
        }
      }
    };
    view.on("change", onChange);

    const updateTick = setInterval(() => {
      if (!isPlaying()) return;

      const pos = ref.current.currentTime;

      if (Math.abs(dbPos - pos) > 2 && pos > 2)
        view.setValue({ path: "pos", value: pos });

      if (parseInt(ref.current.duration, 10) - 2 < pos)
        view.setValue({ path: "playState", value: "ended" });
    }, updateInterval || 300);

    return () => {
      view.setValue({ path: "pos", value: video.currentTime });
      if (updateTick) clearInterval(updateTick);
      view.off("change", onChange);
    };
  }, [updateInterval, view]);

  useEffect(() => {
    if (!window.caches) return;

    window.caches.open("nystem").then(async (cache) => {
      const cached = await cache.match(src);
      if (!cached) return;

      const blob = new Blob([await cached.arrayBuffer()], {
        type: `video/${ext}`,
      });
      setCached(URL.createObjectURL(blob));
    });
  }, [ext, src]);

  if (!id) return null;

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      width="320"
      height="240"
      className={className}
      ref={ref}
      controls={controls}
      preload={preload}
      src={cached || src}
      type={`video/${ext}`}
    >
      Your browser does not support the video element.
    </video>
  );
};
export default MediaYtsyncPlayer;
