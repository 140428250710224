import {
  Link,
  Wrapper,
  SessionLogout,
  ConnectionView,
  Image,
  ExtensionView,
  Icon,
  ViewLinkSite,
} from "nystem-components";
import React, { useState, useEffect } from "react";
import app from "nystem";

const MenuLink = ({ format, children, className }) => (
  <Link
    className={`hover:text-gray-300 px-2 block rounded-md ${className || ""}`}
    to={`/youtubeVideo/${format}`}
    match={`/youtubeVideo/${format}`}
  >
    {children}
  </Link>
);

const PartMenu = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const close = () => {
      if (open === true) setOpen(false);
    };
    setTimeout(() => {
      window.addEventListener("click", close);
    }, 0);
    return () => {
      window.removeEventListener("click", close);
    };
  }, [open]);

  return (
    <Wrapper
      renderAs="header"
      className="bg-menu py-1 fixed top-0 z-20 w-full pl-3 pr-2 md:mx-0"
    >
      <Wrapper
        renderAs="nav"
        className="flex text-primary max-w-6xl m-auto text-sm justify-center items-center"
      >
        <Link to="/" exact="true">
          <ConnectionView>
            <Image
              className="text-red pr-2"
              width={40}
              height={30}
              src="/image/youtube.svg"
              alt="logo"
            />
          </ConnectionView>
          <ConnectionView offline="true">
            <Image
              className="text-red grayscale pr-2"
              width={40}
              height={30}
              src="/image/youtube.svg"
              alt="logo"
            />
          </ConnectionView>
        </Link>
        <Wrapper className="flex flex-grow">
          <MenuLink className="py-3" format="incoming">
            Incoming
          </MenuLink>
          <MenuLink className="py-3" format="watchLater">
            Watch later
          </MenuLink>
          <MenuLink
            className="py-3 hide-in-extension hidden sm:block"
            format="list"
          >
            Watched
          </MenuLink>
          <MenuLink format="all" className="py-3">
            All
          </MenuLink>
          <MenuLink
            format="player"
            className="py-3 hide-in-extension hidden sm:block"
          >
            Player
          </MenuLink>
          <Link
            className="py-3 px-2 block hover:text-gray-200"
            to="/youtubeChannel/list"
            match="/youtubeChannel*"
          >
            Channels
          </Link>
          <ExtensionView>
            <ViewLinkSite
              model={{
                text: "Site",
                className: "py-3 px-2 block hover:text-gray-200",
              }}
            />
          </ExtensionView>
        </Wrapper>
        <Icon
          icon="bars"
          className="w-8 h-8 cursor-pointer"
          onClick={() => setOpen(!open)}
        />
        <Wrapper className="relative w-1">
          {open ? (
            <Wrapper className="bg-menu absolute text-primary w-32 mt-6 right-0 text-sm">
              <MenuLink className="py-1 text-center" format="incoming">
                Incoming
              </MenuLink>
              <MenuLink className="py-1 text-center" format="watchLater">
                Watch later
              </MenuLink>
              <MenuLink format="list" className="py-1 text-center">
                Watched
              </MenuLink>
              <MenuLink format="all" className="py-1 text-center">
                All
              </MenuLink>
              <MenuLink format="player" className="py-1 text-center">
                Player
              </MenuLink>
              <Link
                className="py-1 px-2 block text-center hover:text-gray-200"
                to="/youtubeChannel/list"
                match="/youtubeChannel*"
              >
                Channels
              </Link>
              <SessionLogout
                type="button"
                className="py-1 px-2 mb-2 block text-center cursor-pointer hover:text-gray-200"
              >
                {app().t("Log out")}
              </SessionLogout>
            </Wrapper>
          ) : null}
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};
export default PartMenu;
