import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const BootstrapCssClassByVal = ({ model, path, value }) => {
  const { condition, item, className } = model;

  const getClass = (value) => {
    const addClass = [];
    // 0-field, 1-vlaue, 2-cssClass
    for (let i = 0; condition && i < condition.length; i++)
      if (value[condition[i][0]] === condition[i][1])
        addClass.push(condition[i][2]);
    return ` ${addClass.join(" ")}`;
  };

  const addClass = getClass(value);

  return (
    <Wrapper className={[...className, addClass]}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};

export default BootstrapCssClassByVal;
