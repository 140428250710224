import { useEffect, useState, useRef } from "react";
import { Wrapper, Image, Icon } from "nystem-components";

const formats = ["maxres", "mq"];

const youtubeImageFormat = (value, format) => {
  const valArr = (value || "").split("/");
  valArr[valArr.length - 1] = format + valArr[valArr.length - 1];
  return valArr.join("/");
};

const UrlImageYoutube = ({ value, model, view }) => {
  const [at, setAt] = useState(0);
  const url = youtubeImageFormat(value, formats[at]);
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    ref.current.onerror = () => {
      console.log("error", view.value);
    };
    ref.current.onload = () => {
      if (ref.current.naturalWidth === 120) setAt(1);
    };
  }, [value, view.value]);

  if (!value || at === 100)
    return (
      <Wrapper className={model.classNameEmpty}>
        <Icon icon="brands-youtube" style={{ width: "100%" }} />
      </Wrapper>
    );

  return (
    <Image
      ref={ref}
      className={model.className}
      src={url}
      width={model.width}
      draggable={!model.notDraggable}
    />
  );
};

export default UrlImageYoutube;
