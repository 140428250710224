import {
  Inserter,
  Wrapper,
  SessionRole,
  PartMenu,
  DragAndDropContext,
  RouterToTopOnNav,
  Pointer,
  StyleMultiClick,
} from "nystem-components";
import "../../../index.css";
import "./index.css";

const Index = () => (
  <DragAndDropContext>
    <Pointer />
    <RouterToTopOnNav />
    <SessionRole userrole="logged-out">
      <Inserter
        className="max-w-6xl m-auto p-3 text-gray-500"
        match="*"
        source="/adminUser/login"
      />
    </SessionRole>
    <Inserter match="/background.html" source="/youtubeVideo/extension" />
    <SessionRole userrole="logged-in">
      <PartMenu />
      <Inserter
        match="*"
        source="/adminUser/logndebug"
        className="max-w-6xl m-auto"
      />
      <Wrapper renderAs="main" className="bg-black text-primary mt-12">
        <Inserter match="/popup.html" source="/youtubeVideo/incoming" />
        <Inserter match="/" source="/youtubeVideo/swipe" />
        <Inserter match="/youtubeChannel*" className="max-w-6xl m-auto" />
        <Inserter match="/tag*" className="max-w-6xl m-auto" />
        <Inserter match="/youtubeVideo/player" />
        <Inserter
          match="/youtubeVideo/watchLater"
          className="max-w-6xl m-auto"
        />
        <Inserter match="/youtubeVideo/view*" className="max-w-6xl m-auto" />
        <Inserter match="/youtubeVideo/tmp*" className="max-w-6xl m-auto" />
        <Inserter match="/youtubeVideo/list" className="max-w-6xl m-auto" />
        <Inserter match="/youtubeVideo/all" className="max-w-6xl m-auto" />
        <Inserter match="/youtubeVideo/incoming" className="max-w-6xl m-auto" />
        <Inserter match="/youtubeVideo/input/*" className="max-w-6xl m-auto" />
        <Inserter
          match="/youtubeArchivedVideo/input/*"
          className="max-w-6xl m-auto"
        />
        <Inserter
          match="/youtubeArchivedVideo/all"
          className="max-w-6xl m-auto"
        />
        <StyleMultiClick model={{}} />
      </Wrapper>
    </SessionRole>
  </DragAndDropContext>
);
export default Index;
