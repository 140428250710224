import React, { useEffect, useState } from "react";
import { FloatTimeSec } from "nystem-components";

const MediaVideoPos = ({ model, value = 0, view }) => {
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (model.show !== "preview") return;

    const videoPreview = ({ pos }) => {
      setPreview(pos);
    };
    view.on("videoPreview", videoPreview);

    return () => {
      view.off("videoPreview", videoPreview);
    };
  }, [model.show, view]);

  const display =
    model.show === "preview"
      ? preview
      : model.show === "duration"
      ? value.duration
      : value.pos;

  return <FloatTimeSec view={view} model={model} value={display} />;
};

export default MediaVideoPos;
