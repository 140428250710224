import React, { useEffect, useRef, useState } from "react";

const MediaVideoPreview = ({ value, view, model }) => {
  const { id, ext } = value || {};
  const { updateInterval, className, preload } = model;
  const [cached, setCached] = useState();
  const src = `/video/${id}.${ext}`;

  const ref = useRef();

  const valRef = useRef();
  valRef.current = value;

  useEffect(() => {
    if (!ref.current) return;
    const video = ref.current;

    const error = (e) => {
      console.log("error", e.target.src);
    };
    video.addEventListener("error", error);

    const videoPreview = ({ pos }) => {
      video.currentTime = pos;
    };
    view.on("videoPreview", videoPreview);

    return () => {
      view.off("videoPreview", videoPreview);
    };
  }, [updateInterval, view]);

  useEffect(() => {
    if (!window.caches) return;

    window.caches.open("nystem").then(async (cache) => {
      const cached = await cache.match(src);
      if (!cached) return;

      const blob = new Blob([await cached.arrayBuffer()], {
        type: `video/${ext}`,
      });
      setCached(URL.createObjectURL(blob));
    });
  }, [ext, src]);

  if (!id) return null;

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      width="320"
      height="240"
      className={className}
      ref={ref}
      preload={preload}
      src={cached || src}
      type={`video/${ext}`}
    >
      Your browser does not support the video element.
    </video>
  );
};
export default MediaVideoPreview;
