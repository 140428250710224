import React from "react";
import { ExtensionIcon } from "nystem-components";

const ExtensionIconByField = ({ model, value }) => {
  const newModel = ["text", "color", "icon"].reduce(
    (res, key) => (model[key] ? { ...res, [key]: value[model[key]] } : res),
    {}
  );

  return <ExtensionIcon model={newModel} />;
};
export default ExtensionIconByField;
