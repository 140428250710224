import React from "react";
import app from "nystem";
import { Wrapper, ContentTypeRender } from "nystem-components";

const ViewButtonAppEventContainer = ({ model, view, path }) => (
  <Wrapper
    onClick={() => {
      app().event(model.event, model.settings);
    }}
    className={model.className}
  >
    <ContentTypeRender path={path} items={model.item} />
  </Wrapper>
);

export default ViewButtonAppEventContainer;
