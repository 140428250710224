import { useEffect, useState, useRef } from "react";
import { Wrapper, Image, Icon } from "nystem-components";

const UrlImageView = ({ value, model }) => {
  const [at, setAt] = useState(0);
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    ref.current.onerror = () => {
      console.log("error", value);
      setAt(100);
    };
  }, [value]);

  if (!value || at === 100)
    return (
      <Wrapper className={model.classNameEmpty || model.className}>
        <Icon icon="brands-youtube" style={{ width: "100%" }} />
      </Wrapper>
    );

  return (
    <Image
      ref={ref}
      className={model.className}
      src={value}
      width={model.width}
      draggable={!model.notDraggable}
    />
  );
};

export default UrlImageView;
