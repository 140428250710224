import React from "react";
import app from "nystem";
import { Wrapper } from "nystem-components";

class ViewButtonAppEvent extends React.Component {
  event = (event) => {
    const { model } = this.props;
    event.preventDefault();
    app().event(model.event, model.settings);
  };
  render() {
    const { model } = this.props;
    const className = model.className || [];
    return (
      <Wrapper
        renderAs="button"
        onClick={this.event}
        className={[...className, "btn", `btn-${model.btnType}`]}
      >
        {app().t(model.text)}
      </Wrapper>
    );
  }
}
export default ViewButtonAppEvent;
