import React, { useState, useEffect } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const makeUrl = ({ model, value, add, view }) => {
  const { id, ext, type } = value;
  let tokenId = false;
  if (model.secure) {
    tokenId = app().uuid();
    const { contentType } = view;
    app().connection.emit({
      type: "secureFileToken",
      tokenId: `${tokenId}.${ext}`,
      fileId: `${id}.${ext}`,
      ext,
      contentType,
      modelId: model.id,
    });
  }
  const secure = model.secure ? "/secure" : "";
  return `/${type}${model.addToPath || ""}${secure}/${add || ""}${
    tokenId || id
  }.${ext}`;
};

const componentByType = {
  audio: ({ value, model, view }) => makeUrl({ model, value, view }),
  image: ({ value, model, view }) => {
    const { width, height } = app().image.calculateSize(value, model);
    const add =
      value.ext === "svg" || model.fullSize ? "" : `${width}x${height}/`;

    return makeUrl({ model, value, add, view });
  },
  video: ({ value, model, view }) => makeUrl({ model, value, view }),
  attachment: ({ value, model, view }) =>
    `${makeUrl({ model, value, view })}?name=${value.name}`,
};

const MediaCached = ({ model, view, value }) => {
  const [visible, setVisible] = useState(undefined);
  const { className, renderAs, item, itemNot } = model;
  value = value instanceof Array ? value : value && [value];

  const urls =
    value &&
    value.map((value) => componentByType[value.type]({ value, model, view }));

  useEffect(() => {
    if (!value || !window.caches) setVisible(false);
    else
      window.caches.open("nystem").then(async (nystemCache) => {
        const keys = (await nystemCache.keys()).map(
          (item) => `/${item.url.split("/").slice(3).join("/")}`
        );

        const notCached = urls.find((url) => !keys.includes(url));

        setVisible(!notCached);
      });
  }, [urls, value]);

  if (visible === undefined) return null;
  if (!visible && !itemNot?.length) return null;

  return (
    <Wrapper className={className} renderAs={renderAs}>
      <ContentTypeRender items={visible ? item : itemNot} />
    </Wrapper>
  );
};

export default MediaCached;
