import React, { useState, useRef, useEffect } from "react";
import app from "nystem";
import { Wrapper, ContentTypeRender } from "nystem-components";

const BootstrapFullScreen = ({ model }) => {
  const [isFullScreen, setIsFullscreen] = useState();
  const fullScreenRef = useRef();

  useEffect(() => {
    const elem = fullScreenRef.current;

    const setFullscreen = () => {
      if (elem.requestFullscreen) elem.requestFullscreen();
      else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
      else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();
      else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();
    };

    const exitFullscreen = () => {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    };

    const toggleFullscreen = () => {
      if (isFullScreen) exitFullscreen();
      else setFullscreen();
    };

    const fullscreenchange = () => {
      if (isFullScreen === document.webkitIsFullScreen) return;

      setIsFullscreen(document.webkitIsFullScreen || document.mozFullScreen);

      app().fullscreenSize = {
        fullscreen: document.webkitIsFullScreen || document.mozFullScreen,
        width: elem.offsetWidth,
        height: elem.offsetHeight,
      };

      app().event("fullscreenchange", app().fullscreenSize);
    };

    app().fullscreenSize = {
      fullscreen: document.webkitIsFullScreen || document.mozFullScreen,
      width: elem.offsetWidth,
      height: elem.offsetHeight,
    };

    app().on("exitFullscreen", exitFullscreen);
    app().on("toggleFullscreen", toggleFullscreen);
    app().on("setFullscreen", setFullscreen);

    document.addEventListener(
      "webkitfullscreenchange",
      fullscreenchange,
      false
    );

    document.addEventListener("mozfullscreenchange", fullscreenchange, false);
    document.addEventListener("fullscreenchange", fullscreenchange, false);
    document.addEventListener("MSFullscreenChange", fullscreenchange, false);

    return () => {
      app().off("setFullscreen", setFullscreen);
      app().off("exitFullscreen", exitFullscreen);
      app().off("toggleFullscreen", toggleFullscreen);
      document.removeEventListener(
        "webkitfullscreenchange",
        fullscreenchange,
        false
      );
      document.removeEventListener(
        "mozfullscreenchange",
        fullscreenchange,
        false
      );
      document.removeEventListener("fullscreenchange", fullscreenchange, false);
      document.removeEventListener(
        "MSFullscreenChange",
        fullscreenchange,
        false
      );
    };
  }, [isFullScreen]);

  const style = isFullScreen
    ? {
        width: "100%",
        height: "100%",
      }
    : {};

  return (
    <Wrapper ref={fullScreenRef} style={style} className={model.className}>
      <ContentTypeRender path={model.path} items={model.item} />
    </Wrapper>
  );
};

export default BootstrapFullScreen;
