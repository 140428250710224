import { useEffect } from "react";
import app from "nystem";

const BooleanSwipe = ({ setValue, model, value }) => {
  useEffect(() => {
    const handleChange = (event) => {
      console.log(event);
      if (value === model.setState) return;

      if (model.count && parseInt(model.count, 10) !== event.count) return;

      const delta = model.vertical ? event.dy : event.dx;

      if (model.limit > 0 ? delta - model.limit > 0 : delta - model.limit < 0)
        setValue(model.id, model.setState);
    };

    app().on("pointer", handleChange);
    return () => {
      app().off("pointer", handleChange);
    };
  }, [model, setValue, value]);

  return null;
};
export default BooleanSwipe;
