import { useEffect, useState } from "react";

const FloatSkipSponsor = ({ view, value, setValue }) => {
  const [spons, setSponse] = useState();
  useEffect(() => {
    const update = async () => {
      const resp = await fetch(
        `https://sponsor.ajay.app/api/skipSegments/?videoID=${view.value._id}`
      );
      if (resp.status !== 200) return;

      setSponse(await resp.json());
    };
    const onChange = ({ id }) => {
      if (!id || id === "_id") update();
    };
    view.on("change", onChange);
    update();

    return () => {
      view.off("change", onChange);
    };
  }, [view]);

  useEffect(() => {
    if (!spons) return;
    const skip = spons.find((item) => {
      const [from, to] = item.segment;
      return value > from && value < to;
    });

    if (skip) setValue(skip.segment[1]);
  }, [value, spons, setValue]);

  return null;
};

export default FloatSkipSponsor;
