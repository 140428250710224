import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Icon,
  InputWrapper,
  Wrapper,
  ContentTypeRender,
} from "nystem-components";
import app from "nystem";

const useValue = (view, listenId) => {
  const [val, setVal] = useState(view.value[listenId]);
  const setViewVal = (value) => {
    view.setValue({ path: listenId, value });
  };

  useEffect(() => {
    const onChange = ({ id, value }) => {
      if (id === listenId) setVal(value[id]);
    };

    view.on("change", onChange);
    return () => {
      view.off("change", onChange);
    };
  });
  return [val, setViewVal];
};

const MediaInteractiveSlider = ({ model, view }) => {
  const [id] = useState(app().uuid);
  const [pos, setPos] = useValue(view, "pos");
  const [duration] = useValue(view, "duration");
  const { classNameInput } = model;
  const [over, setOver] = useState(false);
  const ref = useRef();
  const circle = useRef();

  useEffect(() => {
    if (!over) return;
    let pos;
    const size = ref.current.getBoundingClientRect();

    const mouseMove = (e) => {
      if (size.top - 30 > e.clientY || size.bottom + 30 < e.clientY)
        setOver(false);

      if (!pos) pos = e.clientX;
      let at = e.clientX - size.left;
      if (at > size.right - 10) at = size.right - 10;
      if (at < 10) at = 10;

      const sendPos = ((at - 10) / (size.width - 20)) * duration;
      view.event("videoPreview", { pos: sendPos });
      if (circle.current) circle.current.style.left = `${at}px`;
      // setStyle({ left: `${at}px` });
    };

    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, [duration, over, view]);

  const onClick = ({ nativeEvent: e }) => {
    const size = ref.current.getBoundingClientRect();
    let at = e.clientX - size.left;
    if (at > size.right - 10) at = size.right - 10;
    if (at < 10) at = 10;

    setPos(((at - 10) / (size.width - 20)) * duration);
  };

  return (
    <InputWrapper id={id} model={model} className={model.className}>
      <Wrapper ref={ref} onMouseOver={() => setOver(true)} onClick={onClick}>
        {over && (
          <>
            <Wrapper className="w-full absolute bottom-0">
              <ContentTypeRender items={model.popup} />
            </Wrapper>
            <Wrapper className="w-full">
              <Icon ref={circle} icon="circle" className="w-4 h-4 absolute" />
            </Wrapper>
          </>
        )}
        <Input
          placeholder={app().t(model.text)}
          className={classNameInput}
          value={parseInt(pos * 10, 10) || 0}
          onChange={(value) => setPos(value / 10)}
          type="range"
          min={0}
          max={parseInt(duration * 10, 10)}
        />
      </Wrapper>
    </InputWrapper>
  );
};

export default MediaInteractiveSlider;
