import { useEffect } from "react";
import app from "nystem";
import { useHistory } from "react-router";

const RouterSwipe = ({ model }) => {
  const history = useHistory();

  useEffect(() => {
    const handleChange = (event) => {
      if (model.count && parseInt(model.count, 10) !== event.count) return;

      const delta = model.vertical ? event.dy : event.dx;

      if (model.limit > 0 ? delta - model.limit > 0 : delta - model.limit < 0)
        history.push(model.path);
    };

    app().on("pointer", handleChange);
    return () => {
      app().off("pointer", handleChange);
    };
  }, [history, model]);

  return null;
};
export default RouterSwipe;
