import React from "react";
import app from "nystem";
import { Wrapper } from "nystem-components";

const BooleanLabelIf = ({ model, value }) => {
  if (!value) return null;

  return (
    <Wrapper renderAs={model.renderAs} className={model.className}>
      {app().t(model.text)}
    </Wrapper>
  );
};

export default BooleanLabelIf;
