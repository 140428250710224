import { useEffect } from "react";
import app from "nystem";

const ViewButtonAddFromPage = ({ model, view, setValue }) => {
  useEffect(() => {
    const onMessage = async ({ hrefs }) => {
      if (!hrefs) return;

      hrefs.forEach(async (href) => {
        const id = href.replace("https://www.youtube.com/watch?v=", "");

        const { data } = await app().database[view.contentType].get({ id });
        if (data) return;

        await app().database[view.contentType].save({
          data: { _id: id },
          view: view.format,
          fetch: true,
        });
      });
    };
    window.chrome.runtime.onMessage.addListener(onMessage);
    return () => {
      window.chrome.runtime.onMessage.removeListener(onMessage);
    };
  }, [view]);

  return null;
};

export default ViewButtonAddFromPage;
