import React, { useState } from "react";
import { Wrapper, Image, Icon } from "nystem-components";

const formats = ["maxres", "mq"];

const youtubeImageFormat = (value, format) => {
  const valArr = (value || "").split("/");
  valArr[valArr.length - 1] = format + valArr[valArr.length - 1];
  return valArr.join("/");
};

const UrlImageViewPort = ({ model, view, value }) => {
  const [to, setTo] = useState(0);
  const [val, setVal] = useState(youtubeImageFormat(value, formats[0]));

  const onLoad = (e) => {
    if (
      (e.nativeEvent && e.nativeEvent.error) ||
      (e.currentTarget && e.currentTarget.naturalWidth === 120)
    )
      if (to < formats.length) {
        setVal(youtubeImageFormat(value, formats[to]));
        setTo(to + 1);
      } else setVal(false);
  };

  if (!val)
    return (
      <Wrapper className={model.className}>
        <Icon className="fa fa-ban" style={{ width: "100%" }} />
      </Wrapper>
    );

  return (
    <Wrapper className={model.className}>
      <Image
        src={val}
        width={window.innerWidth}
        draggable={!model.notDraggable}
        onError={onLoad}
        onLoad={onLoad}
      />
    </Wrapper>
  );
};

export default UrlImageViewPort;
