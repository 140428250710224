import { useEffect } from "react";

import app from "nystem";

const Pointer = () => {
  useEffect(() => {
    let state = {
      dx: 0,
      dy: 0,
    };
    let start = false;

    const pos = (event) =>
      event.clientX
        ? {
            x: event.clientX,
            y: event.clientY,
          }
        : pos(event.touches && event.touches[0]);

    const mouseDown = (event) => {
      start = pos(event);
    };
    const mouseMove = (event) => {
      if (!start) return;

      const current = pos(event);
      const count = (event.touches && event.touches.length) || 1;

      state = {
        start,
        current,
        dx: current.x - start.x,
        dy: current.y - start.y,
        count,
      };
    };
    const mouseUp = (event) => {
      start = false;
      if (!state.current) return;

      app().event("pointer", state);

      state = {
        start,
        current: false,
        dx: 0,
        dy: 0,
      };
    };
    document.addEventListener("mousedown", mouseDown, false);
    document.addEventListener("mousemove", mouseMove, false);
    document.addEventListener("mouseup", mouseUp, false);
    document.addEventListener("touchstart", mouseDown, false);
    document.addEventListener("touchmove", mouseMove, false);
    document.addEventListener("touchend", mouseUp, false);

    return () => {
      document.removeEventListener("mousedown", mouseDown, false);
      document.removeEventListener("mousemove", mouseMove, false);
      document.removeEventListener("mouseup", mouseUp, false);
      document.removeEventListener("touchstart", mouseDown, false);
      document.removeEventListener("touchmove", mouseMove, false);
      document.removeEventListener("touchend", mouseUp, false);
    };
  }, []);

  return null;
};
export default Pointer;
