import React, { useEffect, useRef } from "react";
import { Wrapper } from "nystem-components";

const addScript = (src) =>
  new Promise((resolve) => {
    const script = [...document.head.children].find(
      (child) => child.getAttribute("src") === src
    );

    if (script) {
      if (script.getAttribute("data-loaded")) resolve();
      else script.addEventListener("load", resolve);

      return;
    }

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("src", src);
    scriptEl.addEventListener("load", () => {
      scriptEl.setAttribute("data-loaded", true);
      resolve();
    });
    document.head.appendChild(scriptEl);
  });

const YoutubePreview = ({ view, value, model }) => {
  const { updateInterval } = model;

  useEffect(() => {
    let toPos = 0;
    let timer;

    const videoPreview = ({ pos }) => {
      if (!player) return;

      if (!toPos)
        timer = setTimeout(() => {
          if (!player) return;
          player.seekTo(toPos);
          toPos = 0;
        }, 500);
      toPos = pos;
    };
    view.on("videoPreview", videoPreview);

    let player = false;

    const loadPlayer = () => {
      if (typeof window.YT !== "undefined" && window.YT.Player)
        player = new window.YT.Player("youtubeplayer", {
          width: "4096",
          height: "2160",
          modestbranding: true,
          videoId: view.value._id,
          cc_load_policy: 0,
          events: {
            onReady: () => {
              player.playVideo();
            },
            onStateChange: () => {
              setTimeout(() => {
                if (player.getPlayerState() === 1) player.pauseVideo();
              }, 100);
            },
          },
          controls: 0,
          playerVars: {
            autoplay: 0,
            controls: 0,
            rel: 0,
            fs: 0,
          },
        });
      else setTimeout(loadPlayer, 200);
    };
    addScript("https://www.youtube.com/iframe_api").then(loadPlayer);

    return () => {
      if (timer) clearTimeout(timer);
      view.off("videoPreview", videoPreview);
    };
  }, [updateInterval, view]);

  return (
    <Wrapper className={model.className}>
      <div
        style={{ position: "absolute", width: "100%", height: "100%" }}
        id="youtubeplayer"
      />
    </Wrapper>
  );
};

export default YoutubePreview;
