import React, { useEffect, useState } from "react";
import { ContentTypeRender } from "nystem-components";

const transforms = ({ model, acc }) => ({
  fadeOut: () => ({ dy, dx }) => {
    const delta = model.vertical ? dy : dx;
    let opacity = delta / model.limit;
    if (opacity <= 0) return { opacity: 1 };
    opacity = opacity > 1 ? 1 : opacity;
    return { opacity: 1 - opacity };
  },
  fadeIn: () => ({ dy, dx }) => {
    const delta = model.vertical ? dy : dx;
    let opacity = delta / model.limit;
    if (opacity <= 0) return { opacity: 0 };
    opacity = opacity > 1 ? 1 : opacity;
    return { opacity };
  },
  follow: () => ({ dy, dx }) => {
    let left = dx * (model.ampl || 1);
    let top = dy * (model.ampl || 1);
    if (model.acc) {
      left += acc.x;
      top += acc.y;
    }
    return { position: "relative", left: `${left}px`, top: `${top}px` };
  },
  followX: () => ({ dx }) => {
    let left = dx * (model.ampl || 1);
    if (model.acc) left += acc.x;
    return { position: "relative", left: `${left}px` };
  },
  followY: () => ({ dy }) => {
    let top = dy * (model.ampl || 1);
    if (model.acc) top += acc.y;
    return { position: "relative", top: `${top}px` };
  },
  rotateByY: () => ({ dy }) => {
    let deg = dy * (model.ampl || 1);
    if (model.acc) deg += acc.y;
    return {
      transform: `rotate(${deg}deg)`,
      transformOrigin: "50% 100%",
    };
  },
  rotateByX: () => ({ dx }) => {
    let deg = dx * (model.ampl || 1);
    if (model.acc) deg += acc.x;
    return {
      transform: `rotate(${deg}deg)`,
      transformOrigin: "50% 100%",
    };
  },
});

const BootstrapSwipe = ({ model, path }) => {
  const { count, dx, dy } = usePointer(model.count);

  const [acc, setAcc] = useState({
    acc: { x: 0, y: 0 },
  });
  const transform = transforms({ model, acc })[model.transform]();

  useEffect(() => {
    if (count === 0 || (dx === 0 && dy === 0)) return;
    setAcc({
      x: dx + acc.x,
      y: dy + acc.y,
    });
  }, [acc.x, acc.y, count, dx, dy]);

  return (
    <div style={transform(count ? { dx, dy } : { dx: 0, dy: 0 })}>
      <ContentTypeRender path={path} items={model.item} />
    </div>
  );
};

export default BootstrapSwipe;

const pos = (event) =>
  event.clientX
    ? {
        x: event.clientX,
        y: event.clientY,
      }
    : pos(event.touches[0]);

const usePointer = (limit) => {
  const [start, setStart] = useState({ x: 0, y: 0 });
  const [current, setCurrent] = useState({ x: 0, y: 0 });
  const [count, setCount] = useState(0);

  useEffect(() => {
    const mouseDown = (event) => {
      setStart(pos(event));
      setCount(1);
    };

    document.addEventListener("mousedown", mouseDown, false);
    document.addEventListener("touchstart", mouseDown, false);

    return () => {
      document.removeEventListener("mousedown", mouseDown, false);
      document.removeEventListener("touchstart", mouseDown, false);
    };
  }, []);

  useEffect(() => {
    if (!count) return;

    const mouseMove = (event) => {
      setCurrent(pos(event));
      setCount((event.touches && event.touches.length) || 1);
    };

    const mouseUp = () => {
      setCount(0);
    };

    document.addEventListener("mousemove", mouseMove, false);
    document.addEventListener("mouseup", mouseUp, false);
    document.addEventListener("touchmove", mouseMove, false);
    document.addEventListener("touchend", mouseUp, false);

    return () => {
      document.removeEventListener("mousemove", mouseMove, false);
      document.removeEventListener("mouseup", mouseUp, false);
      document.removeEventListener("touchmove", mouseMove, false);
      document.removeEventListener("touchend", mouseUp, false);
    };
  }, [count]);

  return {
    start,
    current,
    count: parseInt(limit || 1, 10) === count ? count : 0,
    dx: current.x - start.x,
    dy: current.y - start.y,
  };
};
