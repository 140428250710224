import React, { useState, useEffect } from "react";
import { Input, InputWrapper } from "nystem-components";
import app from "nystem";

const useValue = (view, listenId) => {
  const [val, setVal] = useState(view.value[listenId]);
  const setViewVal = (value) => {
    view.setValue({ path: listenId, value });
  };

  useEffect(() => {
    const onChange = ({ id, value }) => {
      if (id === listenId) setVal(value[id]);
    };

    view.on("change", onChange);
    return () => {
      view.off("change", onChange);
    };
  });
  return [val, setViewVal];
};

const MediaSlider = ({ model, view }) => {
  const [id] = useState(app().uuid);
  const [pos, setPos] = useValue(view, "pos");
  const [duration] = useValue(view, "duration");
  const { classNameInput } = model;

  return (
    <InputWrapper id={id} model={model} className={model.className}>
      <Input
        placeholder={app().t(model.text)}
        className={classNameInput}
        value={parseInt(pos * 10, 10) || 0}
        onChange={(value) => setPos(value / 10)}
        type="range"
        min={0}
        max={parseInt(duration * 10, 10)}
      />
    </InputWrapper>
  );
};

export default MediaSlider;
