import React from "react";
import app from "nystem";
import { Wrapper } from "nystem-components";

const TextareaViewCutLong = (props) => {
  const { model } = props;
  let val = props.value || model.fallback || "";
  val = val.replace(/([^ ]{40})/g, "$1 ");
  return (
    <Wrapper renderAs={model.renderAs} className={model.className}>
      {app().t(val)}
    </Wrapper>
  );
};

export default TextareaViewCutLong;
