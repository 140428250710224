import { useEffect } from "react";
import app from "nystem";
import { useHistory } from "react-router";

const SelectSetStateSwipe = ({ model }) => {
  const history = useHistory();

  useEffect(() => {
    const handleChange = (event) => {
      const { setValue, model, value } = this.props;
      if (value === model.setState) return;

      const delta = model.vertical ? event.dy : event.dx;

      if (model.limit > 0 ? delta - model.limit > 0 : delta - model.limit < 0)
        setValue(model.id, model.setState);
    };

    app().on("pointer", handleChange);
    return () => {
      app().off("pointer", handleChange);
    };
  }, [history, model]);

  return null;
};
export default SelectSetStateSwipe;
