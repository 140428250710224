import React from "react";
import { Wrapper } from "nystem-components";

const FloatTimeSec = ({ model, value = 0 }) => {
  const h = parseInt(value / 60 / 60, 10);

  let min = parseInt(value / 60, 10) - h * 60;
  if (min < 10) min = `0${min}`;

  let sec = parseInt(value, 10) - h * 60 * 60 - min * 60;
  if (sec < 10) sec = `0${sec}`;

  const className = model.className ? model.className.join(" ") : "";
  return (
    <Wrapper renderAs={model.renderAs} className={className}>
      {`${h ? `${h}:` : ""}${min}:${sec}`}
    </Wrapper>
  );
};

export default FloatTimeSec;
