const ExtensionIcon = (props) => {
  const { browserAction } = window.chrome;
  const { color, text, icon, canvas, clearOnZero } = props.model || props;
  if (icon) browserAction.setIcon({ path: icon });
  if (color) browserAction.setBadgeBackgroundColor({ color });
  if ((props.model || props).hasOwnProperty("text")) {
    const parsedText =
      text === undefined || (clearOnZero && text.toString() === "0")
        ? ""
        : text.toString();
    console.log({ text, clearOnZero, parsedText });
    browserAction.setBadgeText({ text: parsedText });
  }
  if (canvas) {
    const canvasContext = canvas.getContext("2d");
    browserAction.setIcon({
      imageData: canvasContext.getImageData(0, 0, canvas.width, canvas.height),
    });
  }
  return null;
};

export default ExtensionIcon;
