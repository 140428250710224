import React from "react";

const type = {
  zoomOut(props) {
    const { point, canvas } = props;
    const rel = canvas.width / point.width;
    const dx = (-canvas.width / 2 + point.x) * rel;
    const dy = (canvas.height / 2 - point.y) * rel;
    return {
      base: { transition: "all 3s", position: "relative" },
      pre: {
        /*        left: -(point.left * rel / 2) + "px",
        top: -point.top + "px", */
        left: `${dx}px`,
        top: `${dy}px`,
        transform: `scale(${rel}) translateZ(0)`,
      },
      post: {
        left: "0px",
        top: "0px",
        transform: "scale(1) translateZ(0)",
      },
    };
  },
};

class Animate extends React.Component {
  constructor(props) {
    super(props);
    this.transform = type.zoomOut(props);
    this.state = {
      style: Object.assign({}, this.transform.base, this.transform.pre),
      loaded: false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        style: Object.assign({}, this.transform.base, this.transform.post),
        loaded: true,
      });
    }, 1000);
  }
  render() {
    const { className } = this.props;

    return (
      <div className={className} style={this.state.style}>
        {this.props.children}
      </div>
    );
  }
}
export default Animate;
