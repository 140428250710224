import { useEffect } from "react";
import app from "nystem";

const MediaYtdownload = ({ view }) => {
  useEffect(() => {
    const onSearch = ({ data }) => {
      if (!data) return;

      data.forEach((item) => {
        app().event("addToCache", {
          contentType: view.contentType,
          id: item._id,
          addMedia: true,
        });
      });
      const next = data.find((item) => !item.ytdl || item.ytdl === "added");
      if (!next || next.ytdl === "added") return;

      console.log("next", next);
      app().database[view.contentType].save({
        fields: true,
        data: { _id: next._id, ytdl: "add" },
      });
    };
    view.on("search", onSearch);
    return () => {
      view.off("search", onSearch);
    };
  }, [view]);

  return null;
};

export default MediaYtdownload;
